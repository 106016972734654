<template>
  <div class="object-parameters flex">
    <ParametersTabs :active-tab="activeTab" @onChange="changeTab" />
    <ParametersDetails
      v-if="activeTab !== sections.PARAMETERS"
      :active-tab="activeTab"
      :data="templateList"
      :loading="loading"
    />
    <ParametersFields
      v-if="activeTab === sections.PARAMETERS"
      :data="fields"
      @onDelete="deleteField"
      @onAdd="addField"
    />
  </div>
</template>
<script setup>
import { computed, onBeforeMount, ref, watch, getCurrentInstance } from 'vue'

import { createVuexHelpers } from 'vue2-helpers'
import { propertiesApi } from '@/api'
import { useI18n } from '@/hooks/useI18n'
import { refs } from '@/components/unitsNew/helpers/index.js'

import ParametersTabs from './ParametersTabs.vue'
import ParametersDetails from './ParametersDetails.vue'
import ParametersFields from './ParametersFields.vue'

import sections from '@/enums/newUnits/parameters-sections.js'

const { useGetters, useMutations } = createVuexHelpers()

const { activeObject, activeObjectFields, errors } = useGetters('units', [
  'activeObject',
  'activeObjectFields',
  'errors'
])
const {
  SET_CUSTOM_FIELDS_ACTIVE_OBJECT,
  SET_FIELDS_ACTIVE_OBJECT,
  ADD_FIELD_ACTIVE_OBJECT,
  DELETE_FIELD_ACTIVE_OBJECT,
  SET_ERRORS
} = useMutations('units', [
  'SET_CUSTOM_FIELDS_ACTIVE_OBJECT',
  'SET_FIELDS_ACTIVE_OBJECT',
  'ADD_FIELD_ACTIVE_OBJECT',
  'DELETE_FIELD_ACTIVE_OBJECT',
  'SET_ERRORS'
])

const instance = getCurrentInstance()
const $t = useI18n()

const activeTab = ref(sections.TRIPS)
const customFields = ref([])
const loading = ref(false)

const selectProps = [
  'key_tripdetector_movedetect',
  'tripdetector_mileagedetect',
  'key_motohourdetector',
  'video_device_name',
  'fuel_level_use_raw_data'
]

const trips = ref([])

const fuel = ref([])

const engine = ref([])

const video = ref([])

const additional = ref([])

const fields = ref([])

const filter = ref([])

// Тех параметры таб - список слева
const templateList = computed(() => {
  switch (activeTab.value) {
    case sections.TRIPS:
      return trips.value
    case sections.FUEL:
      return fuel.value
    case sections.ENGINE_HOURS:
      return engine.value
    case sections.VIDEO:
      return video.value
    case sections.ADDITIONAL:
      return additional.value
    case sections.FILTER_DATA:
      return filter.value
    default:
      return trips.value
  }
})

// Возвращает массив /топливо/видео/моточасы и пр
const getValues = (group, video = false) => {
  return customFields.value.filter((item) => {
    if (item.group === group) {
      const type = selectProps.includes(item.key) ? 'select' : 'input'
      item.type = type

      if (group === 'cf_etc') {
        const isVideo = item.key.split('_')[0]

        if (isVideo !== 'video' && !video) return item

        if (isVideo === 'video' && video) return item

        return
      }

      return item
    }
  })
}

const groupFields = () => {
  console.log('ВЫЗЫВАЕМ groupFields');
  trips.value = getValues('cf_trips')
  fuel.value = getValues('cf_fuel')
  console.log(fuel.value, 'value');
  engine.value = getValues('cf_motohours')
  video.value = getValues('cf_etc', true)
  additional.value = getValues('cf_etc')
  fields.value = getValues('cf_custom')
  filter.value = getValues('cf_filter')
}

const getFields = () => {
  loading.value = true

  propertiesApi.getCustomFields(
    (res) => {
      let fields = res.data.map((field) => {
        return {
          ...field,
          value: field.default_value
        }
      })

      if (
        activeObject.value.custom_fields &&
        activeObject.value.custom_fields.length
      ) {
        fields = activeObject.value.custom_fields.map((field, idx) => {
          if (field.group === 'cf_custom') {
            field.localId = Math.ceil(Math.random() * (255 - 0) + 0)

            return field
          }

          const theField = res.data.find((item) => item.key === field.key)

          return {
            ...field,
            pattern: theField.pattern,
            default_value: theField.default_value,
            value: field.value || theField.default_value
          }
        })
      }

      SET_CUSTOM_FIELDS_ACTIVE_OBJECT(fields)
      SET_FIELDS_ACTIVE_OBJECT(fields)

      customFields.value = activeObject.value.custom_fields

      groupFields()

      loading.value = false
    },
    (error) => {
      console.log(error, 'errorr')
      loading.value = false
    }
  )
}

const deleteField = (localId) => {
  fields.value = fields.value.filter((item) => item.localId !== localId)

  DELETE_FIELD_ACTIVE_OBJECT(localId)

  if (!fields.value.length && errors.value.length) SET_ERRORS([])
}

const addField = () => {
  const newField = {
    name: '',
    value: '',
    show_in_monitoring: false,
    group: 'cf_custom',
    localId: Math.ceil(Math.random() * (255 - 0) + 0)
  }

  fields.value = [...fields.value, newField]

  ADD_FIELD_ACTIVE_OBJECT(newField)
}

const changeTab = (tab) => {
  const sectionRef = refs.parametersValidation.value

  if (!sectionRef) return (activeTab.value = tab)

  refs.parametersValidation.value.validate((valid) => {
    if (valid) {
      activeTab.value = tab

      return
    }

    instance.proxy.$showError(
      $t('new_units.objects.fields.parameters.errors.correct_values')
    )
  })
}

// Обновим значение в activeObject иначе меняется только когда перходим на другой таб или открываем заново
watch(() => templateList, newValue => {
  newValue.value.forEach(update => {
    const index = activeObject.value.custom_fields.findIndex(item => item.key === update.key)
    if (index !== -1) {
      activeObject.value.custom_fields[index] = { ...activeObject.value.custom_fields[index], ...update }
    }
})
}, {
  deep: true
})

onBeforeMount(() => {
  if (activeObjectFields.value.length) {
    customFields.value = activeObject.value.custom_fields

    groupFields()
  } else {
    getFields()
  }
})
</script>
<style lang="scss">
@import '@/components/unitsNew/assets/styles/Objects/Parameters.scss';
</style>
